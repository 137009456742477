import React from "react";
import { Link } from "react-router-dom";
import { Grid, Image, Card, Container, Icon, Header, List, Item, Segment, Divider } from "semantic-ui-react";

export default class Footer extends React.Component {
  render() {
    const today = new Date();
    const year = today.getFullYear();
    return (
      <Segment className="bgPrimaryDarker" style={{ margin: "0em 0em 0em", padding: "1em 0em" }}>
        <Container textAlign="center">
          <List inverted horizontal divided link>
            <List.Item>© Improvement Service {year}. All Rights Reserved. Powered by TCS Dexam</List.Item>
          </List>
        </Container>
        <Container textAlign="center">
          <List inverted horizontal divided link size="medium">
            {/* <List.Item>© Improvement Service 2024. All Rights Reserved. Powered by TCS Dexam</List.Item> */}
            <List.Item as={Link} to="/help">
              Help
            </List.Item>
            <List.Item as={Link} to="/termsAndConditions">
              Terms & Conditions
            </List.Item>
            <List.Item as={Link} to="/privacyPolicy">
              Privacy Policy
            </List.Item>
            <List.Item as={Link} to="/cookiePolicy">
              Cookie Policy
            </List.Item>
            <List.Item as={Link} to="/accessibilityStatement">
              Accessibility Statement
            </List.Item>
          </List>
        </Container>
      </Segment>
    );
  }
}
